@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

:root{
  --primary-color: #0d4f59;
  --secondary-color: #ecd409;
  --secondary-color-light: #fbf093;
}

html,
body,
#root {
  cursor: default;
}
